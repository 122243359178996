import {action, computed, makeAutoObservable} from "mobx";
import { observable, toJS } from 'mobx';
import RideShare from "../models/Rideshares.model";
import { SystemType } from "../models/systemType.enum";
import { SettingsService } from "../services/settings.service";

export class RideSharesStore {
    @observable
    public rideShares: RideShare[] = [];

    @observable
    public updateList:boolean = false;

    @observable
    public settingsService: SettingsService = new SettingsService();

    constructor() {
        makeAutoObservable(this)
    }
    @computed
    public get allRideShares(): RideShare[] {
        return toJS(this.rideShares).sort((a: RideShare, b:RideShare) => {
            const textA = a.providerName.toLowerCase();
            const textB = b.providerName.toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }

    public getSingleProvider(provider: string): any {
        return this.rideShares.find((item: RideShare) => {
            return item.providerName === provider
        });
    }
    @action
    public setUpdateList(updateList:boolean){
        this.updateList = updateList;
    }

    public overwriteAllRideShares(newItems: RideShare[]) {
        this.rideShares = newItems;
    }

    @action
    public changeOrderInList(index:number,system:SystemType ,type:string,dragIndex: number, hoverIndex: number){
      let newList = this.allRideShares[index] as RideShare
      let list = (newList.systems[system] as any)[type]
      const tempItem = list[hoverIndex];
      list[hoverIndex]= list[dragIndex]
      list[dragIndex] = tempItem;
      (newList.systems[system] as any)[type] = list
      this.updateSinglesRideShares(newList)
      this.updateList = true;
      //window.localStorage.setItem(index + '/' + system, JSON.stringify(list.map((item:any)=>item.name)))

    }
    @action
    public onDropUpdateSettings(index:number,system:SystemType ,type:string){
        let newList = this.allRideShares[index] as RideShare
        this.settingsService.updateSettings({
          path: `services.${newList.providerName}.${'api_strategy_order'}.${system.toLowerCase()}`,
          value: (newList.systems[system] as any)[type].filter((item:any)=>item.enabled).map((item:any)=>item.name).join(',') 
             
       })
    }
    @action
    public initialSort(index:number,system:SystemType ,type:string) {
        const data:string[] = JSON.parse(window.localStorage.getItem(index + '/' + system) || '[]');
        let newList = this.allRideShares[index] as RideShare
        let list = (newList.systems[system] as any)[type] || []
        if(list &&  data.length > 0){
            (list as {name:string}[]).sort((item1:{name:string},item2:{name:string})=>{
                const index = data.findIndex(item=>item1.name === item);
                const index2 = data.findIndex(item=>item2.name === item);
                return index - index2;
            });
        
            (newList.systems[system] as any)[type] = list
            this.updateSinglesRideShares(newList);
            this.setUpdateList(true);
        }
        }
       
    @action
    public updateSinglesRideShares(newRideShare: RideShare) {
        const idx = this.rideShares.findIndex((item: RideShare) => item.providerName === newRideShare.providerName)
        if (idx >= 0) {
            this.rideShares[idx] = newRideShare;
        }
    }

}
