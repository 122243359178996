import environment from '../environment';
import axios, { AxiosResponse } from 'axios';

import { ErrorHandler } from './errorHandler.service';
import {getStorageKey, saveToStorage, getFromStorage} from "./storageCache.service";

export interface IRequestOption {
    data?: Record<string, any> | string; // will add to request payload
    params?: Record<string, any>; // will add to URL
    responseType?: string;
}

export enum ApiType {
    SETTINGS,
    PROFILES,
    AUTH,
    TAXIS,
}

export class ApiService {
    public static URL_PREFIX = environment.backendUrl || ''; // in case of some api url prefix depends on environment

    public static getApiUrl(apiType: ApiType): string {
        switch (apiType) {
            case ApiType.SETTINGS:
                return '/settings';
            case ApiType.PROFILES:
                return '/profiles';
            case ApiType.AUTH:
                return '/auth'
            case ApiType.TAXIS:
                return '/taxis'
            default:
                throw new Error('Unknown ApiType');
        }
    }

    public createUrl(apiType: ApiType, path = '', optionsParams?: Record<string, any>): string {
        let params = '';
        if (optionsParams) {
            for (const [key, value] of Object.entries(optionsParams)) {
                if(value)
                    params += `${params ? '&' : ''}${key}=${value}`;
            }
        }
        return `${ApiService.URL_PREFIX}${ApiService.getApiUrl(apiType)}${path}${params ? `?${params}` : ''}`;
    }

    public instance: any = axios.create({
        headers: {}
    });

    private errorHandler: ErrorHandler = new ErrorHandler();

    constructor() {
        this.instance.interceptors.response.use(
            (response: any) => response,
            (error: any) => {
                this.errorHandler.onError(error);
                return Promise.reject(error);
            },
        );

        this.instance.interceptors.request.use((config: any) => {
            const auth = window.localStorage.getItem('auth')
            if (auth) {
                const obj = JSON.parse(auth);
                config.headers.Authorization = `${obj.token_type} ${obj.access_token}`
            }
            return config;
        });
    }

    public createGetRequest(
        apiType: ApiType,
        url: string,
        options: IRequestOption = {},
        cache = false,
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            const cacheKey = `${getStorageKey(`get_request_${this.createUrl(apiType, url, options.params)}`)}`;
            const fromCache = getFromStorage(cacheKey);
            if (fromCache && cache) {
                resolve(fromCache);
            } else {
                this.createRequest('GET', apiType, url, options)
                    .then((r: AxiosResponse<any>) => {
                        if (cache) {
                            saveToStorage(cacheKey, r);
                        }
                        resolve(r);
                    })
                    .catch((e: any) => {
                        reject(e);
                    });
            }
        });
    }

    public createPostRequest( apiType: ApiType, url: string, options: IRequestOption = {} ): Promise<any> {
        return this.createRequest('POST', apiType, url, options);
    }

    public createPatchRequest( apiType:ApiType, url: string, options: IRequestOption = {} ): Promise<any> {
        return this.createRequest('PATCH', apiType, url, options);
    }

    public createPutRequest( apiType: ApiType, url: string, options: IRequestOption = {} ): Promise<any> {
        return this.createRequest('PUT', apiType, url, options);
    }

    public createDeleteRequest( apiType: ApiType, url: string, options: IRequestOption = {} ): Promise<any> {
        return this.createRequest('DELETE', apiType, url, options);
    }

    private createRequest(method: string, apiType: any, path: string, options: IRequestOption = {}): Promise<any> {
        this.instance.defaults.headers.post['Content-Type'] = 'application/json';

        return this.instance({
            method,
            url: this.createUrl(apiType, path, options.params),
            data: options.data,
            responseType: options.responseType,
        });
    }
}
