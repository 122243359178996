// import { setTimeoutAndClear } from '@gat-vue/common/src/utils/timeout.utils';
import {setTimeoutAndClear} from "../helpers/timeout.utils";
const STORAGE_PREFIX = 'EPOCA_';
const DEFAULT_LIFE_TIME = 1000 * 60; // 1min

const getStorageKey = (key: string): string => {
    return `${STORAGE_PREFIX}_${key}`;
};

const saveToStorage = (key: string, data: any, time = new Date().getTime(), lifeTime = DEFAULT_LIFE_TIME): any => {
    window.sessionStorage.setItem(getStorageKey(key), JSON.stringify(data));
    setTimeoutAndClear(() => {
        removeFromStorage(getStorageKey(key));
    }, DEFAULT_LIFE_TIME);
    return data;
};

const getFromStorage = (key: string): any | null => {
    const stringified = window.sessionStorage.getItem(getStorageKey(key)) as string;
    return stringified === null ? null : JSON.parse(stringified);
};

const removeFromStorage = (key: string): void => {
    window.sessionStorage.removeItem(key);
};

const removeItemStartWith = (key: string): number => {
    const allKey = Object.keys(window.sessionStorage);
    let removed = 0;
    allKey.forEach((item: string) => {
        if (item.startsWith(getStorageKey(key))) {
            removeFromStorage(item);
            removed++;
        }
    });
    return removed;
};

const clearAllCache = (): void => {
    removeItemStartWith(STORAGE_PREFIX);
};

export { getStorageKey, saveToStorage, removeFromStorage, getFromStorage, clearAllCache, removeItemStartWith };
