import React from 'react';
import { ROUTES, ISingleRoute } from "./routes.const";
import { NotFound } from "../common/NotFound/NotFound";
import { Loader } from '../common/Loader/Loader'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

export function Router(): JSX.Element {
    return (
        <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
                <Switch>
                    {Object.values(ROUTES).map((route: ISingleRoute) => {
                        if (route.redirectTo) {
                            return <Redirect key={route.id} to={route.redirectTo} />
                        } else {
                            return (
                                <Route
                                    key={route.id}
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                />
                            )
                        }
                    })}
                    <Route component={NotFound} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    );
}
