import React from 'react';
import style from './Loader.module.scss';

export function Loader(): JSX.Element {
    return (
        <div className={style.loaderWrapper}>
            <p>loading ...</p>
        </div>
    );
}
