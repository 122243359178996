import {makeAutoObservable} from "mobx";
import { observable } from 'mobx';
// import RideShare from "../models/Rideshares.model";
import Bikes from "../models/Bike.model";

export class BikesStore {
    @observable
    private bikes: Bikes[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    public get allBikes(): Bikes[] {
        return this.bikes;
    }

    public getSingleProvider(provider: string): any {
        return this.bikes.find((item: Bikes) => {
            return item.providerName === provider
        });
    }

    public overwriteAllBikes(newItems: Bikes[]) {
        this.bikes = newItems;
    }

    public updateSinglesBike(newItem: Bikes) {
        const idx = this.bikes.findIndex((item: Bikes) => item.providerName === newItem.providerName)
        if (idx >= 0) {
            this.bikes[idx] = newItem;
        }
    }

}
