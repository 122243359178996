import {ApiService, ApiType} from '../api/api.service';

export class SettingsService {
    private apiService: ApiService = new ApiService();

    public getRideShares(): Promise<any> {
        return this.apiService.createGetRequest(ApiType.SETTINGS, '/rideshares')
    }

    public getBikes(): Promise<any> {
        return this.apiService.createGetRequest(ApiType.SETTINGS, '/bikes')
    }

    public updateSettings(payloadData: {path: string, value: string}): Promise<any> {
        return this.apiService.createPostRequest(ApiType.SETTINGS, '', {
            data: {path: payloadData.path.replaceAll("_", "-"), value: payloadData.value}
        })
    }
}
