class TimeoutUtil {
    public static setTimeoutAndClear(action: any, time: number = 0) {
        const timeoutId = setTimeout(() => {
            if (action && typeof action === 'function') {
                action();
            }
            clearTimeout(timeoutId);
        }, time);
        return timeoutId;
    }
}

const setTimeoutAndClear = TimeoutUtil.setTimeoutAndClear;

export { setTimeoutAndClear, TimeoutUtil };
