export class ErrorHandler {
    public onError(e: any): void {
        const errorCode: number = e.response.status;

        // google analytics here

        switch (errorCode) {
            case 500:
                // redirect to sht like "api unavailable"
                break;
            case 401:
                window.localStorage.removeItem('auth')
                window.location.pathname = 'login'
                break;
            case 429:
                // to many request
                break;
            case 403:
                // sth like "not allowed"
                break;
            default:
                // sth like 'WHOPS!!, sth went wrong'
        }
    }
}
