const base = {
    backendUrl: '/v2admin',
    oauthTokenClientId: 'bellhop_test_client_id',
};

const prodChanges = {
    backendUrl: '/api/v2admin',
    // googleAnalyticsId: '',
};


let environment = base;

if (process.env.NODE_ENV === 'production') {
    environment = Object.assign(base, prodChanges);
}

export default environment;
