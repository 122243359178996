/*
    React.lazy and Suspense are not yet available for server-side rendering.
    If you want to do code-splitting in a server rendered app, we recommend Loadable Components.
 */
import { lazy } from 'react';

const LoginView = lazy(() => import('../views/login/LoginView'));
const AdminView = lazy(() => import('../views/admin/AdminView'));
const RideSharesView = lazy(() => import('../views/riderShares/RideSharesView'));
const RideDetailsView = lazy(() => import('../views/rideDetails/rideDetailsView'));
const UsersView = lazy(() => import('../views/users/UsersView'));

export interface ISingleRoute {
    id: string;
    exact?: boolean;
    component?: any;

    label: string;
    path: string;
    redirectTo?: string;
    children?: Record<string, ISingleRoute>

    displayInMainMenu?: boolean;
    // requiresLogin?: boolean;
}

export const ROUTES: Record<string, ISingleRoute> = {
    LOGIN: {
        id: 'LOGIN',
        label: 'login',
        path: '/login',
        exact: true,
        component: LoginView
    },

    ADMIN: {
        id: 'Admin',
        label: 'Admin',
        path: '/',
        component: AdminView,
        // redirectTo: '/rideshares',
        children: {
            RIDESHARES: {
                id: 'Rideshares',
                label: 'Rideshares',
                path: '/rideshares',
                displayInMainMenu: true,
                component: RideSharesView,
            },
            RIDEDETAILS: {
                id: 'Ridedetails',
                label: 'Ride details',
                path: '/ridedetails',
                displayInMainMenu: true,
                component: RideDetailsView,
            },
            USERS: {
                id: 'Users',
                label: 'Users',
                path: '/users',
                displayInMainMenu: true,
                component: UsersView,
            },
        }
    },
};
