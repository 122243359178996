import React, {useMemo} from 'react';
import './App.scss';
import {Router as RouterComponent} from './router/Router.component';
import './stores/rideshares.store';
import {Provider as MobXProvider} from 'mobx-react';
import {RideSharesStore} from "./stores/rideshares.store";
import {BikesStore} from "./stores/bikes.store";
import CssBaseline from '@mui/material/CssBaseline';
import 'material-react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "material-react-toastify";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const App: React.FC = (): JSX.Element => {
    const {rideSharesStore} = useMemo(() => {
        return {
            rideSharesStore: new RideSharesStore(),
        };
    }, []);

    const {bikesStore} = useMemo(() => {
        return {
            bikesStore: new BikesStore(),
        };
    }, []);
    return (
        <div className="App">
            <CssBaseline />
            <MobXProvider
                rideSharesStore={rideSharesStore}
                bikeStore={bikesStore}
            >
                <DndProvider backend={HTML5Backend}>
                    <RouterComponent/>
                </DndProvider>
            </MobXProvider>
            <ToastContainer
                position="top-right"
            />
        </div>
    );
}

export default App;
